import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import LRU from "lru-cache";

const instance = axios.create({
  validateStatus: (status) => status < 500,
  baseURL: process.env.REACT_APP_BASE_URL,
  // auth: process.env.REACT_APP_TOKEN,
});
// instance.defaults.headers.common[
//   "Authorization"
// ] = `token ${process.env.REACT_APP_TOKEN}`;

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("idtoken");
    if (token) request.headers.Authorization = `token ${token}`;
    console.log("request", request);
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log("response", response);
    if (response.status === 401) {
      localStorage.removeItem("idtoken");
      localStorage.removeItem("user");
    }
    // Edit response config
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const cache = new LRU({ max: 10 });

export default makeUseAxios({ cache, axios: instance });
